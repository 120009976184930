
export interface Entity {
  meta_ts: number;
}

export interface PartnerCompanyAggregate {
  id: string;
  company: PartnerCompany;
  daily_earnings_drivers: string[];
}

export interface PartnerCompany {
  name: string;
  area_id: string;
}

export interface DriverAggregate {
  id: string;
  driver: Driver;
  daily_earnings_companies: string[];
  onboarding: DriverOnboarding;
  photo: Photo;
}

export interface Driver {
  first_name: string;
  last_name: string;
  phone: string;
}

export interface Photo extends Entity {
  url: string;
}

export interface Vehicle extends Entity {
  id: string;
  license_plate: string;
}

export interface Settlement {
  id: string;
  created_at: number;
  local_start_date: number;
  local_end_date: number;
  local_start_week: number;
  cash_revenue: Money;
  app_revenue: Money;
  pos_revenue: Money;
  invoice_revenue: Money;
  other_revenue: Money;
  net_revenue: Money;
  organization_service_fee_amount: Money;
  earnings_amount: Money;
  deductible_cash_amount: Money;
  tms_visit_amount: Money;
  tip_amount: Money;
  external_fees_non_cash_amount: Money;
  vehicle_insurance_amount: Money;
  business_liability_insurance_amount: Money;
  occupational_insurance_amount: Money;
  adjustment_amount: Money;
  settlement_amount: Money;
  period_start_debt_amount: Money;
  payout_amount: Money;
}

export interface Shift {
  id: string;
  started_at: number | null,
  ended_at: number | null,
  driver_id: string,
  vehicle_id: string,
  total_distance: number,
  active_distance: number,
  control_distance: number,
  active_duration: number,
  inactive_duration: number,
  cash_revenue: Money,
  partner_gross_revenue: Money,
}

export interface DailyEarningsSplit {
  id: string;
  partner_company_id: string;
  driver_id: string;
  local_date: number;
  tip_amount: Money;
  adjustment_amount: Money;
  cash_revenue: Money;
  app_revenue: Money;
  pos_revenue: Money;
  invoice_revenue: Money;
  other_revenue: Money;
  net_revenue: Money;
  received_cash_amount: Money;
  external_fees_amount: Money;
  external_fees_cash_amount: Money;
  external_fees_non_cash_amount: Money;
  external_fees_and_tms_visit_amount: Money;
  meta_ts: number;
  earnings_ids: string[];
  adjustment_ids: string[];
  tip_ids: string[];
  tip_refund_ids: string[];
  tip_refund_failure_ids: string[];
  external_fee_refund_ids: string[];
  external_fee_refund_failure_ids: string[];
  tms_visit_amount: Money;
  total_amount: Money;
  tms_visit_ids: string[];
}

export interface Money {
  value: number;
  display: string;
  currency: string;
}

export interface CreditNote {
  id: string;
  file_name: string;
}

export interface Partner {
  company_ids: string[]
}

export interface Area {
  id: string;
  language_code: string;
  country_code: string;
}

export interface Earnings {
  id: string;
  booking_id: string;
  reference: string;
  reference_type: EarningsReferenceType;
  earnings_amount: Money;
  net_revenue: Money;
  gross_revenue: Money;
  external_fees: ExternalFee[];
}

export interface ExternalFee {
  id: string;
  amount: Money;
}

export interface BookingAggregate {
  id: string;
  summary?: {
    pickup: Location;
    dropoff: Location;
  };
  job?: {
    in_progress_at: number;
    completed_at: number;
    cancelled_at: number;
    payment_method: PaymentMethod;
  };
  booking?: {
    pickup: Location;
    dropoff: Location;
    payment_method: PaymentMethod;
  };
}

export interface Location {
  lat: number;
  lng: number;
  formatted_address: string;
}

export enum EarningsReferenceType {
  UNDEFINED = 0,
  TRIP = 1,
  REFUND = 2,
  REFUND_FAILURE = 4,
  ADJUSTMENT = 3
}

export interface PaymentMethod {
  type: PaymentMethodType;
  variant: PaymentMethodVariant;
}

export enum PaymentMethodType {
  UNDEFINED = 0,
  ONLINE = 1,
  INVOICE = 4,
  IN_PERSON = 5,
  PAYMENT_LINK = 6
}

export enum PaymentMethodVariant {
  UNDEFINED = 0,
  CASH = 1,
  POS = 2,
  CARD = 3,
  APPLE_PAY = 4,
  GOOGLE_PAY = 5
}

export interface Adjustment {
  id: string;
  amount: Money;
  tag: string;
  created_at: number;
  executed_at: number;
  public_note: string;
}

export interface PaymentRefund {
  amount: Money;
  type: PaymentRefundType;
  completed_at: number;
  failed_at: number;
}

export enum PaymentRefundType {
  UNDEFINED = 0,
  TRANSPORTATION = 1,
  EXTERNAL_FEES = 2
}

export interface Tip {
  amount: Money;
  completed_at: number;
}

export interface TipRefund {
  amount: Money;
  completed_at: number;
  failed_at: number;
}

export interface TmsVisit {
  id: string;
  amount: Money;
  ended_at: number;
}

export interface Area {
  id: string;
  language_code: string;
  country_code: string;
  time_zone_id: string;
  name: string;
}

export interface DriverOnboarding extends Entity {
  driver_id: string;
  area_id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  taxi_license_number: string;
  taxi_license_expires_at: number;
  profile_photo_url: string;
  taxi_license_front_photo_url: string
  taxi_license_back_photo_url: string;
  rejected_at: boolean;
  introduction_video_url: string;
  exam_passed_at: number;
  last_exam_completed_at: number;
  driver_license_number: string;
  driver_license_expires_at: number;
  driver_license_back_photo_url: string;
  driver_license_front_photo_url: string;
  criminal_record_file_name: string;
}

export interface SettingValueCondition {
  value: string;
}

type SettingValue = number;

export interface PlatformSettingValue {
  condition: SettingValueCondition;
  value: SettingValue;
}

export interface PlatformSetting {
  values: PlatformSettingValue[];
}

export type SettingsAggregate = {
  [key in 'driver.onboarding_exam_cooldown_duration']: PlatformSetting;
};
