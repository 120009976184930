import { Injectable } from '@angular/core';
import { take } from 'rxjs';
import {
  Adjustment,
  BookingAggregate,
  Earnings,
  PaymentRefund,
  Tip,
  TipRefund,
  TmsVisit
} from 'src/app/core/models/firestore.model';
import { FirestoreHelperService } from 'src/app/shared/services/firestore-helper.service';

@Injectable({
  providedIn: 'root'
})
export class DailyEarningsFirestoreService {

  constructor(
    private firestoreHelper: FirestoreHelperService,
  ) { }

  getEarnings(earningsIds: string[]) {
    return this.firestoreHelper.colDataByIds<Earnings>(`earnings`, earningsIds).pipe(
      take(1)
    );
  }

  getBookingAggregate(bookingId: string) {
    return this.firestoreHelper.docData<BookingAggregate>(`booking_aggregate/${bookingId}`).pipe(
      take(1)
    );
  }

  getAdjustments(adjustmentIds: string[]) {
    return this.firestoreHelper.colDataByIds<Adjustment>(`adjustment`, adjustmentIds).pipe(
      take(1)
    );
  }

  getAdjustment(adjustmentId: string) {
    return this.firestoreHelper.docData<Adjustment>(`adjustment/${adjustmentId}`).pipe(
      take(1)
    );
  }

  getPaymentRefund(refundId: string) {
    return this.firestoreHelper.docData<PaymentRefund>(`payment_refund/${refundId}`).pipe(
      take(1)
    );
  }

  getExternalFeeRefunds(refundIds: string[]) {
    return this.firestoreHelper.colDataByIds<PaymentRefund>(`payment_refund`, refundIds).pipe(
      take(1)
    );
  }

  getTips(tipIds: string[]) {
    return this.firestoreHelper.colDataByIds<Tip>(`tip`, tipIds).pipe(
      take(1)
    );
  }

  getTipRefunds(tipRefundIds: string[]) {
    return this.firestoreHelper.colDataByIds<TipRefund>(`tip_refund`, tipRefundIds).pipe(
      take(1)
    );
  }

  getTmsVisits(tmsVisitIds: string[]) {
    return this.firestoreHelper.colDataByIds<TmsVisit>(`tms_visit`, tmsVisitIds).pipe(
      take(1)
    );
  }
}
