import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, of, switchMap, take, zip } from 'rxjs';
import { Area, BookingAggregate, DailyEarningsSplit, Earnings, EarningsReferenceType, Money, PaymentMethodType, PaymentMethodVariant } from 'src/app/core/models/firestore.model';
import { LogService } from 'src/app/core/services';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';
import { AmountClass } from '../models/class-types';
import { UNKNOWN_AMOUNT, UNKNOWN_LOCATION, UNKNOWN_TIME, UNKNOWN_TYPE, UNSPECIFIED, UNSPECIFIED_TIME } from '../models/consts';
import { DailyEarningsDetailRow, DetailRowTrip, DetailRowType } from '../models/daily-earnings-detail-row';
import { DailyEarningsFooterRow } from '../models/daily-earnings-footer-row';
import { DailyEarningsRow } from '../models/daily-earnings-row';
import { DailyEarningsFirestoreService } from './daily-earnings-firestore.service';

const UNKNOWN_DETAIL_ROW: DailyEarningsDetailRow = {
  time: UNKNOWN_TIME,
  type: UNKNOWN_TYPE,
  amount: UNKNOWN_AMOUNT,
  amountClass: '',
  timestamp: 0
};

@Injectable({
  providedIn: 'root'
})
export class DailyEarningsService {
  constructor(
    private dailyEarningsFirestore: DailyEarningsFirestoreService,
    private dateHelperService: DateHelperService,
    private log: LogService
  ) { }

  getRows<T extends DailyEarningsRow>(
    dailyEarnings: DailyEarningsSplit[],
    rowMapper: (dailyEarnings: DailyEarningsSplit, row: DailyEarningsRow) => T,
    area?: Area
  ): { rows: T[], footer: DailyEarningsFooterRow | null } {
    if (dailyEarnings.length === 0) {
      return {
        rows: [],
        footer: null
      };
    }

    const result = dailyEarnings.reduce((acc, curr) => {
      return {
        rows: [
          ...acc.rows,
          rowMapper(
            curr,
            {
              id: curr.id,
              date: this.dateHelperService.formatLocalDate(curr.local_date),
              cash: curr.received_cash_amount.display,
              netRevenue: curr.net_revenue.display,
              adjustments: curr.adjustment_amount.display,
              externalFees: curr.external_fees_and_tms_visit_amount.display,
              tips: curr.tip_amount.display,
              total: curr.total_amount.display,
              adjustmentIds: curr.adjustment_ids,
              earningsIds: curr.earnings_ids,
              tipIds: curr.tip_ids,
              tipRefundFailureIds: curr.tip_refund_failure_ids,
              tipRefundIds: curr.tip_refund_ids,
              tmsVisitIds: curr.tms_visit_ids,
              externalFeeRefundIds: curr.external_fee_refund_ids ?? [],
              externalFeeRefundFailureIds: curr.external_fee_refund_failure_ids ?? [],
              totalClass: this.getAmountClass(curr.total_amount),
              isExpanded: false,
              detailRows: []
            } satisfies DailyEarningsRow
          )
        ],
        totals: {
          cash: acc.totals.cash + curr.received_cash_amount.value,
          netRevenue: acc.totals.netRevenue + curr.net_revenue.value,
          adjustments: acc.totals.adjustments + curr.adjustment_amount.value,
          tips: acc.totals.tips + curr.tip_amount.value,
          externalFees: acc.totals.externalFees + curr.external_fees_and_tms_visit_amount.value,
          total: acc.totals.total + curr.total_amount.value
        }
      };
    }, {
      rows: <T[]>[],
      totals: { cash: 0, netRevenue: 0, adjustments: 0, tips: 0, externalFees: 0, total: 0 }
    });

    const currencyFormat = new Intl.NumberFormat(area?.language_code, { style: 'currency', currency: dailyEarnings[0].total_amount.currency });

    return {
      rows: result.rows,
      footer: {
        title: 'Total:',
        cash: currencyFormat.format(result.totals.cash),
        netRevenue: currencyFormat.format(result.totals.netRevenue),
        adjustments: currencyFormat.format(result.totals.adjustments),
        tips: currencyFormat.format(result.totals.tips),
        externalFees: currencyFormat.format(result.totals.externalFees),
        total: currencyFormat.format(result.totals.total)
      }
    };
  }

  getDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    return zip([
      this.getEarningsDetailRows(row),
      this.getAdjustmentDetailRows(row),
      this.getTipDetailRows(row),
      this.getTipRefundDetailRows(row),
      this.getTmsVisitDetailRows(row),
      this.getExternalFeeRefundDetailRows(row)
    ]).pipe(
      take(1),
      map(([earningsDetailRows, adjustmentDetailRows, tipDetailRows, tipRefundDetailRows, tmsVisitRows, externalFeeRefundDetailsRow]) => {
        return [
          ...earningsDetailRows,
          ...adjustmentDetailRows,
          ...tipDetailRows,
          ...tipRefundDetailRows,
          ...tmsVisitRows,
          ...externalFeeRefundDetailsRow
        ].sort((a, b) => a.timestamp - b.timestamp);
      })
    );
  }

  // #region Get detail rows
  private getEarningsDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    return this.dailyEarningsFirestore.getEarnings(row.earningsIds).pipe(
      map((earnings) => {
        return Array.from(earnings).map(([earningsId, earnings]) => {
          if (!earnings) {
            this.log.warn(`Earnings not found: ${earningsId}`);
            return of(UNKNOWN_DETAIL_ROW);
          }

          switch (earnings.reference_type) {
            case EarningsReferenceType.TRIP:
              return this.buildEarningsTripDetailRow(earnings, row.date);
            case EarningsReferenceType.ADJUSTMENT:
              return this.buildEarningsAdjustmentDetailRow(earnings, row.date);
            case EarningsReferenceType.REFUND:
            case EarningsReferenceType.REFUND_FAILURE:
              return this.buildEarningsRefundDetailRow(earnings, row.date);
            default:
              this.log.warn(`Unknown earnings reference type: ${earnings.reference_type}`);
              return of(<DailyEarningsDetailRow>{
                ...UNKNOWN_DETAIL_ROW,
                amount: earnings.net_revenue.display,
                amountClass: this.getAmountClass(earnings.net_revenue)
              });
          }
        });
      }),
      switchMap((detailRow$s) => {
        return detailRow$s.length ?
          forkJoin(detailRow$s)
          : of([]);
      })
    );
  }

  private getAdjustmentDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    const type = 'Adjustment';

    return this.dailyEarningsFirestore.getAdjustments(row.adjustmentIds).pipe(
      map((adjustments) => {
        return Array.from(adjustments).map(([adjustmentId, adjustment]) => {
          if (!adjustment) {
            this.log.warn(`Adjustment not found: ${adjustmentId}`);
          }

          const timestamp = adjustment?.executed_at || adjustment?.created_at;
          return {
            time: this.timestampToTimeString(timestamp),
            type,
            subType: adjustment?.tag,
            amount: adjustment?.amount?.display || UNKNOWN_AMOUNT,
            amountClass: this.getAmountClass(adjustment?.amount),
            timestamp: timestamp || 0,
            publicNote: adjustment?.public_note
          } satisfies DailyEarningsDetailRow;
        });
      })
    );
  }

  private getTipDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    const type = 'Tip';
    return this.dailyEarningsFirestore.getTips(row.tipIds).pipe(
      map((tips) => {
        return Array.from(tips).map(([tipId, tip]) => {
          if (!tip) {
            this.log.warn(`Tip not found: ${tipId}`);
          }

          const timestamp = tip?.completed_at;
          return {
            time: this.timestampToTimeString(timestamp),
            type,
            amount: tip?.amount?.display || UNKNOWN_AMOUNT,
            amountClass: '',
            timestamp: timestamp || 0,
          } satisfies DailyEarningsDetailRow;
        });
      })
    );
  }

  private getTipRefundDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    return this.dailyEarningsFirestore.getTipRefunds([...row.tipRefundIds, ...row.tipRefundFailureIds]).pipe(
      map((tipRefunds) => {
        return Array.from(tipRefunds).map(([tipRefundId, tipRefund]) => {
          if (!tipRefund) {
            this.log.warn(`Tip refund not found: ${tipRefundId}`);
          }

          const isFailed = !!tipRefund?.failed_at;
          const timestamp = tipRefund?.failed_at || tipRefund?.completed_at || 0;
          const type = tipRefund
            ? isFailed
              ? 'Tip refund failure'
              : 'Tip refund'
            : UNKNOWN_TYPE;
          const refundAmount = isFailed
            ? tipRefund?.amount
            : tipRefund ? this.toNegativeAmount(tipRefund.amount) : undefined;
          const amount = refundAmount?.display || UNKNOWN_AMOUNT;
          const amountClass = this.getAmountClass(refundAmount);
          return {
            time: this.timestampToTimeString(timestamp),
            type,
            amount,
            amountClass,
            timestamp: timestamp,
          } satisfies DailyEarningsDetailRow;
        });
      })
    );
  }

  private getExternalFeeRefundDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    return this.dailyEarningsFirestore.getExternalFeeRefunds([...row.externalFeeRefundIds, ...row.externalFeeRefundFailureIds]).pipe(
      map((externalFeeRefunds) => {
        return Array.from(externalFeeRefunds).map(([externalFeeRefundId, externalFeeRefund]) => {
          if (!externalFeeRefund) {
            this.log.warn(`External fee refund not found: ${externalFeeRefundId}`);
          }

          const isFailed = !!externalFeeRefund?.failed_at;
          const timestamp = externalFeeRefund?.failed_at || externalFeeRefund?.completed_at || 0;
          const type = externalFeeRefund
            ? isFailed
              ? 'External fee refund failure'
              : 'External fee refund'
            : UNKNOWN_TYPE;
          const refundAmount = isFailed
            ? externalFeeRefund?.amount
            : externalFeeRefund ? this.toNegativeAmount(externalFeeRefund.amount) : undefined;
          const amount = refundAmount?.display || UNKNOWN_AMOUNT;
          const amountClass = this.getAmountClass(refundAmount);
          return {
            time: this.timestampToTimeString(timestamp),
            type,
            amount,
            amountClass,
            timestamp: timestamp
          } satisfies DailyEarningsDetailRow;
        });
      })
    );
  }

  private getTmsVisitDetailRows(row: DailyEarningsRow): Observable<DailyEarningsDetailRow[]> {
    const type = 'TMS visit to be paid';

    return this.dailyEarningsFirestore.getTmsVisits(row.tmsVisitIds).pipe(
      map((visits) => {
        return Array.from(visits).map(([visitId, visit]) => {
          if (!visit) {
            this.log.warn(`Visit not found: ${visitId}`);
          }

          const timestamp = visit?.ended_at;
          const amount = visit?.amount ? this.toNegativeAmount(visit.amount) : undefined;
          return {
            time: this.timestampToTimeString(timestamp),
            type,
            amount: amount?.display || UNKNOWN_AMOUNT,
            amountClass: this.getAmountClass(visit?.amount),
            timestamp: timestamp || 0
          } satisfies DailyEarningsDetailRow;
        });
      })
    );
  }
  // #endregion

  // #region Detail row builders
  private buildEarningsTripDetailRow(earnings: Earnings, dailyEarningsDate: string): Observable<DailyEarningsDetailRow> {
    const amount = earnings.gross_revenue.display;
    return this.dailyEarningsFirestore.getBookingAggregate(earnings.reference).pipe(
      map((bookingAggregate) => {
        if (!bookingAggregate) {
          this.log.warn(`Booking aggregate not found for earnings reference ${earnings.reference}`);
        }

        const timestamp = bookingAggregate?.job?.completed_at;
        const type = this.bookingAggregateToTripEarningsType(bookingAggregate);
        const amountClass = type === 'Failed to collect payment'
          ? 'loss'
          : type === 'Payment link'
            ? 'neutral'
            : this.getAmountClass(earnings.gross_revenue);

        return {
          time: this.timestampToTimeString(timestamp),
          type,
          trip: this.bookingAggregateToDetailRowTrip(bookingAggregate, dailyEarningsDate),
          amount,
          amountClass,
          timestamp: timestamp || 0
        } satisfies DailyEarningsDetailRow;
      })
    );
  }

  private buildEarningsAdjustmentDetailRow(earnings: Earnings, dailyEarningsDate: string): Observable<DailyEarningsDetailRow> {
    return zip([
      this.dailyEarningsFirestore.getAdjustment(earnings.reference),
      this.dailyEarningsFirestore.getBookingAggregate(earnings.booking_id)
    ]).pipe(
      take(1),
      map(([adjustment, bookingAggregate]) => {
        if (!adjustment) {
          this.log.warn(`Adjustment not found: ${earnings.reference}`);
        }

        if (!bookingAggregate) {
          this.log.warn(`Booking aggregate ${earnings.booking_id} not found on earnings ${earnings.id}`);
        }

        let type: DetailRowType;
        switch (adjustment?.tag) {
          // These values come from the Console app
          case 'Other revenue':
          case 'Trip correction':
          case 'Refunded trip':
            type = adjustment.tag;
            break;
          default:
            type = '????';
        }

        const timestamp = adjustment?.executed_at || adjustment?.created_at;
        return {
          time: this.timestampToTimeString(timestamp),
          type,
          trip: this.bookingAggregateToDetailRowTrip(bookingAggregate, dailyEarningsDate),
          amount: earnings.gross_revenue.display,
          amountClass: this.getAmountClass(earnings.gross_revenue),
          timestamp: timestamp || 0,
          publicNote: adjustment?.public_note
        } satisfies DailyEarningsDetailRow;
      })
    );
  }

  private buildEarningsRefundDetailRow(earnings: Earnings, dailyEarningsDate: string): Observable<DailyEarningsDetailRow> {
    return zip([
      this.dailyEarningsFirestore.getPaymentRefund(earnings.reference),
      this.dailyEarningsFirestore.getBookingAggregate(earnings.booking_id)
    ]).pipe(
      map(([paymentRefund, bookingAggregate]) => {
        if (!paymentRefund) {
          this.log.warn(`Payment refund not found for earnings ${earnings.id} reference ${earnings.reference}`);
        }

        if (!bookingAggregate) {
          this.log.warn(`Booking aggregate not found for earnings ${earnings.id} booking_id ${earnings.booking_id}`);
        }

        const isFailed = !!paymentRefund?.failed_at;
        const type = paymentRefund
          ? isFailed
            ? 'Trip refund failure'
            : 'Trip refund'
          : UNKNOWN_TYPE;
        const timestamp = paymentRefund?.failed_at || paymentRefund?.completed_at || 0;
        return {
          time: this.timestampToTimeString(timestamp),
          type,
          trip: this.bookingAggregateToDetailRowTrip(bookingAggregate, dailyEarningsDate),
          amount: earnings.gross_revenue.display,
          amountClass: this.getAmountClass(earnings.gross_revenue),
          timestamp: timestamp
        } satisfies DailyEarningsDetailRow;
      })
    );
  }
  // #endregion

  // #region Helpers
  private bookingAggregateToTripEarningsType(bookingAggregate: BookingAggregate | null): DetailRowType {
    if (!bookingAggregate) {
      return UNKNOWN_TYPE;
    }

    if (bookingAggregate.job?.cancelled_at) {
      return 'Cancelled trip';
    }

    const paymentMethod = bookingAggregate?.job?.payment_method || bookingAggregate?.booking?.payment_method;
    if (!paymentMethod) {
      return 'Failed to collect payment';
    }
    switch (paymentMethod.type) {
      case PaymentMethodType.ONLINE:
        return 'App revenue';
      case PaymentMethodType.INVOICE:
        return 'Invoice revenue';
      case PaymentMethodType.PAYMENT_LINK:
        return 'Payment link';
      case PaymentMethodType.IN_PERSON: {
        const earningsType = paymentMethod.variant === PaymentMethodVariant.CASH
          ? 'Cash revenue'
          : paymentMethod.variant === PaymentMethodVariant.POS
            ? 'Card revenue'
            : null;
        if (!earningsType) {
          this.log.warn(`Unknown payment method variant for payment method type IN_PERSON: ${paymentMethod.variant}`);
          return UNKNOWN_TYPE;
        }
        return earningsType;
      }
      default:
        this.log.warn(`Unknown payment method type: ${paymentMethod?.type}`);
        return UNKNOWN_TYPE;
    }
  }

  private bookingAggregateToDetailRowTrip(bookingAggregate: BookingAggregate | null, dailyEarningsDate?: string): DetailRowTrip {
    if (!bookingAggregate || !bookingAggregate.summary || !bookingAggregate.job || !bookingAggregate.booking) {
      return {
        startedAt: UNKNOWN_TIME,
        endedAt: UNKNOWN_TIME,
        pickup: UNKNOWN_LOCATION,
        destination: UNKNOWN_LOCATION,
        destinationClass: ''
      };
    }

    const isCancelled = !!bookingAggregate.job.cancelled_at;
    const startedAt = isCancelled
      ? bookingAggregate.job.in_progress_at
        ? this.timestampToTimeString(bookingAggregate.job.in_progress_at, dailyEarningsDate)
        : UNSPECIFIED_TIME
      : this.timestampToTimeString(bookingAggregate.job.in_progress_at, dailyEarningsDate);
    const endedAt = isCancelled
      ? bookingAggregate.booking.dropoff?.formatted_address
        ? UNSPECIFIED_TIME
        : ''
      : this.timestampToTimeString(bookingAggregate.job.completed_at, dailyEarningsDate)
    const pickup = bookingAggregate.summary.pickup?.formatted_address
      || bookingAggregate.booking.pickup?.formatted_address
      || UNKNOWN_LOCATION;
    const requestedDropoff = bookingAggregate.booking.dropoff;
    const actualDropoff = bookingAggregate.summary.dropoff;
    const destination = isCancelled
      ? requestedDropoff?.formatted_address
      || UNSPECIFIED
      : actualDropoff?.formatted_address
      || requestedDropoff?.formatted_address
      || UNKNOWN_LOCATION;
    return {
      startedAt,
      endedAt,
      pickup,
      destination,
      destinationClass: destination === UNSPECIFIED ? 'unspecified' : ''
    } satisfies DetailRowTrip;
  }

  private timestampToTimeString(timestamp: number | undefined, referenceDate?: string): string {
    return timestamp
      ? referenceDate && this.dateHelperService.format(timestamp) !== referenceDate
        ? this.dateHelperService.format(timestamp, 'p P')
        : this.dateHelperService.format(timestamp, 'p')
      : UNKNOWN_TIME;
  }

  private getAmountClass(amount: Money | undefined): AmountClass {
    if (!amount) {
      return '';
    }

    return amount.value > 0
      ? ''
      : amount.value < 0
        ? 'credit'
        : 'neutral';
  }

  private toNegativeAmount(amount: Money): Money {
    return {
      ...amount,
      display: String.fromCharCode(8722) + amount.display, // Use the same character as backend.
      value: -amount.value
    };
  }
  // #endregion
}
